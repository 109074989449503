import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Gorive cijevi - Proizvodi | Protupožarna zaštita',
  description: '',
  image: require('@assets/images/protupozarno-brtvljenje.jpg'),
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarno-brtvljenje/`, name: 'Protupožarno brtvljenje'},
        { link: `/proizvodi/protupozarno-brtvljenje/negorive-cijevi/`, name: 'Negorive cijevi'},
      ]}
    />
    <AlternativeHero
      description=""
      slim
      title="Negorive cijevi"
      subtitle="Proizvodi - Protupožarno brtvljenje"
    />
    <Products activeCategory="negorive-cijevi" />
  </Page>
)

export default Category